import React, { Component, useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./Topmenu.css";

function Topmenu() {
  const [ key, setKey ] = useState("");
  return (<>
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
      
        <Navbar.Brand href="#home"><img className="logo" alt="logo" src="http://28535051.s21i.faiusr.com/3/ABUIABADGAAg-ofcjgYottHjmAYw4RA4pwU.gif"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link eventKey="aboutus">關於我們</Nav.Link>
            <Nav.Link href="#course">課程</Nav.Link>
            
            <NavDropdown title="活動花絮" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">2023荃灣居民舞蹈匯演</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                2022-2023
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">2021-2022</NavDropdown.Item>
              {/* <NavDropdown.Divider /> */}
              {/* <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}
            </NavDropdown>
            <Nav.Link href="#contactus">聯絡我們</Nav.Link>

          </Nav>

        </Navbar.Collapse>
        
      </Container>
    </Navbar>
    <Tabs
    id="controlled-tab-example"
    activeKey={key}
    onSelect={(k) => setKey(k)}
    className="mb-3"
  >
    <Tab eventKey="aboutus" title="Home">
      Tab content for aboutus
    </Tab>
      </Tabs>
      </>
  );
}

export default Topmenu;